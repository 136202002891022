export const isServerEnv=true;
export const rooturl=isServerEnv?"https://app.idverify.com.ng/api": "https://localhost:7087/api";
export const paystack_public='';
export const baseurl=isServerEnv?"https://www.idverify.com.ng": "http://localhost:3000";
export const excelPath=`${baseurl}/`;

export function seo(data = {}) {
    data.title = data.title || 'ID Verify';
    data.metaDescription = data.metaDescription || 'ID Verify';
  
    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }
  export const ReduceString=(value, max)=>{
    if(value.length>max){
       var s=value.substring(0,max)
      return s+'...';
    }else{
    return value;
    }
  }
  export const isNumber=(value)=> {
    //console.log(`${typeof value} and the isNAN ${!Number.isNaN(value)}`)
      return  !Number.isNaN(value);
    
    }
    export const Distinct=(arr)=>{
      return Array.from( new Set( arr ) ) ;
    }
    export const b64encode = (txt)=> { 
        return btoa(unescape(encodeURIComponent(txt))); 
    };
    export const b64decode = (txt) =>{ 
        return decodeURIComponent(escape(atob(txt))); 
    };

    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'NGN',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    export const formatDate=(inputDateTime)=> {
      const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      
        hour12: true,
      };
    
      const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
      return formattedDateTime;
    }
    export const formatTime=(inputDateTime)=> {
      const options = {
       
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      };
    
      const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
      return formattedDateTime;
    }
  
    export const formatCurrency=(num)=>{
     // return parseFloat(num).toFixed(2);
     return formatter.format(num);
    }
    /* export const dateOnly=(value) =>{
      let dstring=  moment(value).format("YYYY-MM-DD");
      return dstring;
      //let d=new Date(dstring);
      //return d;
     } */
    export const QrCodelink=(str)=>{
        return `https://quickchart.io/qr?text=${str}&size=100`;
      }
    export const serverErr="Could not connect to the service at the moment. Please try again.";
    export const vPAssErr="The Password and Verify Password should be same.";