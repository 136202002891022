import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Bvn from  '../../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import * as Constants from '../../Constants';
import tinData from '../../data/finger.json';
import IdInput from '../../components/idInput';
import IdSelect from '../../components/idSelect';
import LoadingImg from '../../images/loading.gif';

function Reports() {
    const { profile,raw,activeType,token } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [param, setParam] = useState({startdate:new Date(), enddate:new Date, usertype:'all', verifytype:'all', specificUserId:0, byEmail:'', reportType:'summary'})
    const [reports, setReports] = useState([]);
    const [isResult, setIsResult]= useState(false);
    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    const [users, setUsers]= useState([]);
    const [totalunit, setTotalUnit]=useState(0);
   // const {type} = useParams(); 
    
    //const data=bvnData.ResponseData;

  console.log(tinData);

    useEffect(() => { 
        
        Constants.seo({title:'Reports',metaDescription:''}); 
        if(profile?.canAddUser){
          Load();
        }


    }, []);

    const onPrint=()=>{
        window.print();
    }
    const types=[{value:'all',text:'All Verifiaction Type'},{value:'nin',text:'NIN'}, {value:'nin-phone',text:'Nin Search by Phone'}, {value:'nin-bvn',text:'Nin Search by BVN'}, 
       {value:'vnin',text:'Virtual Nin'}, {value:'nin-phone',text:'Nin Search by Phone'}, {value:'bvn',text:'Direct BVN'}]

       const usertype =[{value:'all', text:'All User Type'},{value:'customer', text:'Customer'}, {value:'agent', text:'Agent'},  {value:'admin', text:'admin'}]
const reportType =[{value:'summary',text:'Summary Report'}, {value:'details',text:'Details Report'}]
       const onSearch=async(e)=>{
        e.preventDefault(); 
        
  
          setLoading(true);
          
          try {
            var pp={...param,reportFormat:'web'}
            const resp = await fetch(`${Constants.rooturl}/verify/report/verify`, {
              method: 'POST',
              body:JSON.stringify(pp) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': token,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                //setGazettes(data.gazettes);
                setIsResult(true);
                setReports(data.reports);
                const totalUnits = data.reports.reduce((sum, item) => sum + item.unitUsed, 0);
                setTotalUnit(totalUnits);
            }else if(data.code == -1) {   
              setErr('issues');
            }
            else if(data.code == -2) {   
              setErr('issues');
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            setErr(Constants.serverErr);
           // onMove();
          }
  
         }

         const onBack=()=>{
          setIsResult(false);
         }

         const Load=async()=>{
      
          var urls=`${Constants.rooturl}/verify/report`;
          try {
              setLoading(true);
              const resp = await fetch(urls, { 
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                  
                },
              });
              
              const data = await resp.json();
            
              setLoading(false);
              console.log(data);

              if (data.code === 0) {
                var _u=[];
                _u.push({value:0, text:`All`});
                data.users.forEach(user => {
                  _u.push({value:user.id, text:`${user.firstName} ${user.lastName==null?'':user.lastName}`});
                });

                setUsers(_u);
                  
              }else {    
                console.error(data.status);
              }
            } catch (error) {
              setLoading(false);
              console.error(error); 
            }
       }     

       const onDownloadExcel=async(e)=>{
        e.preventDefault(); 
          setLoading(true);
          
          try {
            var pp={...param,reportFormat:'excel'}
            const resp = await fetch(`${Constants.rooturl}/verify/report/verify`, {
              method: 'POST',
              body:JSON.stringify(pp) ,
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'responseType': 'blob'
              },
            });
            
           const data = await resp.blob();
            setLoading(false);
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            link.download ="idverifyReport"// 'some.pdf';
            link.href = URL.createObjectURL(blob);
            link.click();
            

          } catch (error) {
            setLoading(false);
            console.error(error);
            setErr(Constants.serverErr);
           // onMove();
          }
  
         }
      const onDownloadExcel2=async(e)=>{
        e.preventDefault(); 
          setLoading(true);
          
          try {
            var pp={...param,reportFormat:'excel'}
            const resp = await fetch(`${Constants.rooturl}/verify/report/verify`, {
              method: 'POST',
              body:JSON.stringify(pp) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': token,
          
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                //setGazettes(data.gazettes);
                window.location.href=`${Constants.excelPath}${data.filename}`;
            }else if(data.code == -1) {   
              setErr('issues');
            }
            else if(data.code == -2) {   
              setErr('issues');
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            setErr(Constants.serverErr);
           // onMove();
          }
  
         }

  return (

   <IdLayout active={'reports'} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

        {!isResult && <div>
            <h2>Report</h2>
            <form onSubmit={onSearch} >
              <div style={{display:'flex', flexDirection:'row'}}>
              <IdSelect onChange={(e)=> setParam({...param, reportType:e.target.value})} value={param.reportType} disabledText="Select Report Type" items={reportType} label='Report Type' required />
                <IdSelect onChange={(e)=> setParam({...param, verifytype:e.target.value})} value={param.verifytype} disabledText="Select Type" items={types} label='Verify Type' required />
               { profile?.canAddUser && <IdSelect onChange={(e)=> setParam({...param, specificUserId:e.target.value})} value={param.specificUserId} disabledText="Select Specific User" items={users} label='Select User' required />} 
               {profile?.userType=='Admin' && <IdSelect onChange={(e)=> setParam({...param, usertype:e.target.value})} value={param.usertype} disabledText="User Type" items={usertype} label='User Type' required />}
               
              </div>
              <div style={{display:'flex', flexDirection:'row'}}>
                <IdInput type='date' label='startdate' value={param.startdate} onChange={(e)=> setParam({...param, startdate:e.target.value})} required/>
                <IdInput type='date' label='enddate' value={param.enddate} onChange={(e)=> setParam({...param, enddate:e.target.value})} required/>
              </div>
            {profile?.userType=='Admin' && <div>
            <IdInput type='text' label='By Email' value={param.byEmail} onChange={(e)=> setParam({...param, byEmail:e.target.value})} />
            </div>}
              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
              {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='greenbutton' style={{width:'100%'}} type="submit">Get Report</button>
                        </div>         
                        
                              </div>} 
            </form>
            <div>

            </div>
          </div>}  
       {isResult &&    <div>
          <h3> Total Units: {totalunit}</h3>
          <button type="button" className='greenbutton' onClick={onDownloadExcel}>Export Excel</button>
          <table className='specialtable'> 
    <tr><th>Verify Type</th>
   {param.reportType=='details' && <th>The Request</th>} 
    <th>Units</th>
    <th>Customer/Agent</th>
    <th>Email</th>
    {param.reportType=='details' && <th>Date</th>}
    </tr>
    {reports?.map((rep, idx) =>{return (
         <tr>
            <td>{rep.type}</td>
            {param.reportType=='details' && <td>{rep.params}</td>} 
            <td>{rep.unitUsed}</td>
            <td>{rep.firstName} {rep.lastName}</td>
            <td>{rep.email}</td>
            {param.reportType=='details' && <td>{Constants.formatDate(rep.createDate) }</td>} 
         </tr>
     )})}
   </table>
          </div>}
              
               
            </div>

<div>
{isResult && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                <button className=' no-print' onClick={onBack} >Back</button>
                              </div>} 
</div>

    </div>
   </IdLayout>
  )
}

export default Reports