import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Bvn from  '../../images/bvn.jpg';
import LoadingImg from '../../images/loading.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import * as Constants from '../../Constants';

//import cacData from '../../data/cac.json';
import IdInput from '../../components/idInput';
import IdSelect from '../../components/idSelect';


function Setting() {
    const { profile,raw,activeType, token } = useContext(ShareContext);
    const navigate = useNavigate();
    const [staySigned, setStaySigned] = useState(false)
    const [view, setView] =useState('profile');
    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    const [company, setCompany] =useState({});
    const [subview, setSubView] =useState('notcom'); //notcom is a state where you switch to 'com' so you can have company info, or 'api' already company details with API key
    const [plans, setPlans]=useState([{value:1,text:'super Bundle'}])
    const [credentials, setCredentials]=useState([]);
    const [users, setUsers]= useState([]);
    const [isadmin, setIsAdmin]=useState(false);
    const [iscompany, setIsCompany] = useState(false);
    const [companies, setCompanies] =useState([]);
    const [plan, setPlan]=useState({});
    const [userType, setUserType]=useState('');
    const [payments, setPayments] = useState([]);
   
  
   // const {type} = useParams(); 
    
    //const data=bvnData.ResponseData;

  //console.log(cacData);

    useEffect(() => { 
       // Constants.seo('','');
        Constants.seo({title:'IdVerify Settings',metaDescription:''}); 
        if(profile!=null){
            LoadSetting();
            LoadPlans();
        }

        LoadCompanies();
        
    }, [profile]);

    const onPrint=()=>{
        window.print();
    }

    const onSaveCompany=async(e)=>{
        e.preventDefault(); 
  
          setLoading(true);
          
          try {
            var v={...company, status:'pending',currentPlanId:1, adminUserId:profile.id};
            const resp = await fetch(`${Constants.rooturl}/customer/company`, {
              method: 'POST',
              body:JSON.stringify(v) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': token,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                //setGazettes(data.gazettes);
                
               setCompany({...company, id:data.id}) ;
               setCredentials([data.cre]);
  
              
            }else if(data.code==-10){
              navigate('/login')
            }else {   
              setErr('Either same company name already exist for another agent');
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            setErr(Constants.serverErr);
           // onMove();
          }
  
         }

    const LoadSetting= async()=>{ //doctype=upline always this one
        //for account registration DocumentView
        const url=`${Constants.rooturl}/customer/settings`
    
        console.log(`the calling url ${url}`);
        try {
            setLoading(true);
            const resp = await fetch(url, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
           console.log(data);
            if(data.code==0 ){
              // navigate(`/AffidavitDocuments/${data.docId}/edit/${data.uplineId}`)

              //if(data.iscompany){
                setCompany(data.com);
              setCredentials(data.credentials);
              setUsers(data.users);
              setIsCompany(data.iscompany);
              setIsAdmin(data.isadmin);
              setCompanies(data.companies);
              setUserType(data.userType);
              setPayments(data.payments);
              //}
              
             
            }else if(data.code==-10){
              navigate('/login')
            }
            else{
               // setAlert({open:true, title:'Payment Status', subtitle:data.status, code:data.code, data, onOk})
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            
          }
    }

    const LoadCompanies= async()=>{ //doctype=upline always this one
      //for account registration DocumentView
      const url=`${Constants.rooturl}/customer/companies`
  
      console.log(`the calling url ${url}`);
      try {
          setLoading(true);
          const resp = await fetch(url, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
         console.log(data);
          if(data.code==0 ){
            // navigate(`/AffidavitDocuments/${data.docId}/edit/${data.uplineId}`)

            if(data.iscompany){
              setCompany(data.coms);
              setPlans(data.plans);
          
            }
          }else{
             // setAlert({open:true, title:'Payment Status', subtitle:data.status, code:data.code, data, onOk})
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          
        }
  }

    const onPlanChange = (e) => {
      setPlan({...plan, [e.target.name]: e.target.value})
    }

    const onSavePlan=async(e)=>{
      e.preventDefault(); 

        setLoading(true);
        
        try {
          var v={...plan, createdBy:profile.id};
          const resp = await fetch(`${Constants.rooturl}/customer/plan`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': token,
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              
            setPlan({...plan, id:data.id}) ;
            setPlans([...plans,{...plan, id:data.id}]); //add the extra
            setCredentials([data.cre]);

            
          }else {   
            setErr('username/password not correct');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
        // onMove();
        }

      }
   
    const LoadPlans = () => {


      fetch(`${Constants.rooturl}/customer/plans`,{
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Authorization": token
          },
      })
      .then((resp) => resp.json())
      .then((data) => {
          setPlans(data);
        
      }).catch((error) => {
          console.log(error)
          setLoading(false)
      })
    
    
      }
    const onUpdateCompany=async(com)=>{
      //to update company details
     // e.preventDefault(); 
  
      setLoading(true);
      
      try {
        var v={...com};
        const resp = await fetch(`${Constants.rooturl}/customer/companies/${com.id}`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
            //setGazettes(data.gazettes);
        //completed.

          
        }else if(data.code==-10){
          navigate('/login')
        }else {   
          setErr('Either same company name already exist for another agent');
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        setErr(Constants.serverErr);
       // onMove();
      }
    }


  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>
        
        <div  className='idtab'>
            <div className={`tabitem ${view=='profile'?'tabactive':''}`} onClick={()=>setView('profile')}>Profile</div>
            <div className={`tabitem ${view=='api'?'tabactive':''}`} onClick={()=>setView('api')}> API</div>
            <div className={`tabitem ${view=='payment'?'tabactive':''}`} onClick={()=>setView('payment')}> Payment History</div>
          {userType=='superadmin' && <>
            <div className={`tabitem ${view=='com'?'tabactive':''}`} onClick={()=>setView('com')}> Agent/Company Request</div>
            <div className={`tabitem ${view=='plan'?'tabactive':''}`} onClick={()=>setView('plan')}> Plan</div>
          </>}  
        </div>
           
{view=='profile' && <div>
    <div className='ninsearch'>
      <div className='cdiv'>
        <div><span>First Name:</span> <span>{profile?.firstName}</span> </div>
        <div><span>Last Name:</span> <span>{profile?.lastName}</span> </div>
        <div><span>Email:</span> <span>{profile?.email}</span> </div>
        <div><span>Wallet Balance:</span> <span>{profile?.unitBalance}</span> </div>
        <button>Change Password</button>
      </div>
        
    </div>
    </div>}

 {view=='api' && <div>
              {!iscompany && <div>
                    <p>This is not a company/agent account, whould you want to make it so</p>
                    <button onClick={()=> setIsCompany(true)}>Change account to Agent/Company</button>
                </div>}  
             {iscompany && <div>
                    <p>You do not have any setup Agent/Company Account</p>
                    <form onSubmit={onSaveCompany}> 
                        <h3>Company/Agent Details</h3>
                        <IdInput label='Company/Agent Name' value={company?.name} onChange={(e)=> setCompany({...company, name:e.target.value})} />
                        <IdInput label='Address' value={company?.address} onChange={(e)=> setCompany({...company, address:e.target.value})}/>
                        <IdInput label ='Contact Person Email' value={company?.email} onChange={(e)=> setCompany({...company, email:e.target.value})} />
                        <IdInput label='Contact Person Phone' value={company?.phone} type='number' onChange={(e)=> setCompany({...company, phone:e.target.value})} />
                        {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                    <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                    <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                    </div>}  
                        {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                    
                            <button className='greenbutton' style={{width:'100%'}} type="submit">Save Company Details</button>
                                </div>} 
                    </form> 
                </div>}   

            {credentials.length>0 &&  <div>

                {
                      credentials?.length>0 &&  credentials?.map((cre, id) => {
                          return (
                            <div key={id}>
                    <p>Your API Key Details <span style={{color:cre?.keyType=='demo'? 'red':'green'}}>{cre?.keyType}</span></p>
                    <div>
                        <p><span>Private Key: </span> <span>{cre.privateKey}</span> </p><button>Generate New</button>
                    </div>
                    </div>
                          )
                        })
                      }
                   
                    
                </div>}    
                
    </div>} 

    {view=='payment' && <div>
    payment History

   <table className='specialtable'> 
    <tr><th>Reference ID</th><th>Amount</th><th>Units</th><th>Date</th><th>Plan</th></tr>
    {payments?.map((pay, idx) =>{return (
         <tr>
            <td>{pay.payRef}</td>
            <td>{pay.amount}</td>
            <td>{pay.balanceUnit}</td>
            <td>{pay.createDate}</td>
            <td>{pay.planIdApplied}</td>
         </tr>
     )})}
   </table>
    </div>}   
    {userType=='superadmin' && <>
        {view=='com' && <div className='table-wrapper '>
        AgentCompany request list (only for SuperAdmin)
        <table className='specialtable'>
            <tr>
                <th>Agent Name</th>
                
                <th>Contact Phone</th>
                <th>Contact Email</th>
                <th>Wallet Balance</th>
                <th>Status</th>
                <th>Plan</th>
                <td>Action</td>
            </tr>
            <tr>
                  <td>Justweb Technology</td>
                  <td>08129225090</td>
                  <td>afriprog@gmail.com</td>
                  <td>0</td>
                  <td >
                    <div  className='inputtext'>
                      <select>
                    <option value='pending'>Pending</option>
                    <option value='enable'>Enable</option>
                    <option value='disable'>Disable</option>
                    </select>
                    </div>
                    
                  </td>
                  <td>
                    <div className='inputtext'>
                      <select >
                        {plans?.map((item, idx) =>{return (
                          <option key={idx} value={item.id}>{item.name}</option>
                        )})}
                      
                      </select>
                    </div>
                    
                  </td>
                  <td><button>Save</button> </td>
              </tr>
            {companies?.map((item, idx) =>{return (
              <tr>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>{item.unitBalance}</td>
                <td >
                  <div  className='inputtext'>
                    <select>
                  <option value='pending'>Pending</option>
                  <option value='enable'>Enable</option>
                  <option value='disable'>Disable</option>
                  </select>
                  </div>
                  
                </td>
                <td>
                  <div className='inputtext'>
                    <select >
                      {plans?.map((item, idx) =>{return (
                        <option key={idx} value={item.id}>{item.name}</option>
                      )})}
                    
                    </select>
                  </div>
                  
                </td>
                <td><button>Save</button> </td>
            </tr>          
                      )})}
            
        </table>

      {/*  <div>
            <div>
                <h3>Justweb Technology</h3> <span>Back</span>
            </div>
            <div>
                <IdSelect items={plans} Label="Plan" />
                <button>Save Plan</button> 
            </div>
            <div>
                Enable Agent
                <button>Save Agent Details</button> 
            </div>
            <div>
                Disable Agent
                <button>Save Agent Details</button> 
            </div>
        </div> */}
        </div>}  
        {view=='plan' && 
        <div>
          <div>
            <form onSubmit={onSavePlan}>
              <IdInput label='Plan Name' value={plan.name} name="name" onChange={onPlanChange} />
            <IdInput label='Unit cost' value={plan.costPerUnit}  name="costPerUnit" onChange={onPlanChange}/>
            <IdInput label ='Min Unit' value={plan.minUnit} name="minUnit"  onChange={onPlanChange}/>
            <div style={{display:'flex'}}>
              <button type='submit'  className='greenbutton middle'> Save</button>
            </div>
            
            </form>
            
          </div>

          <div>
            <table className='specialtable'>
              <tr>
                <th>Name</th>
                <th>Cost Per Unit</th>
                <th>Min Unit</th>
                <th>Action</th>
              </tr>
              {plans.map((item, idx) =>{return (

                    <tr key={idx} >
                      <td>{item.name}</td>
                      <td>{item.costPerUnit}</td>
                      <td>{item.minUnit}</td>
                      <td><Link to="">Edit</Link></td>
                    </tr>
                  )})}
              
              
            </table>
          </div>

          </div>} 
    </>}        
               
   </div>

<div>

</div>

    </div>
   </IdLayout>
  )
}

export default Setting