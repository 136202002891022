import React, { useState , useEffect, useContext} from 'react';


import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import IdInput from '../../components/idInput';

function NinPhone() {
    const { profile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const {type} = useParams(); 
    
    
  return (

   <IdLayout active={type} profile={profile}>
    <div>
            <div style={{display:'flex'}}>
                <div style={{flex:1, backgroundColor:'white', display:'flex', flexDirection:'row'}}>
                     <div style={{height:369, flex:1, margin:'auto'}}>
                        <img src={Logo} style={{width:300}} />
                        </div>
                        <div style={{flex:1}}>
                         
                          <div style={{flex:1}}> <IdInput label="Phone" /></div>
                          <div><button className='greenbutton'>Search</button></div>
                          
                        </div>
               
                </div>
               
               
            </div>
    </div>
   </IdLayout>
  )
}

export default NinPhone