import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Bvn from  '../../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import * as Constants from '../../Constants';
import bvnData from '../../data/sampleBVNData.json';



function BvnSearchResult() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
   // const {type} = useParams(); 
    
    const data=raw?.ResponseData;

  

    useEffect(() => { 
     Constants.seo({title:'BVN Search',metaDescription:''});   
    }, []);

    const onPrint=()=>{
        window.print();
    }

  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

            <div style={{display:'flex', flexDirection:'row', flex:1}}>
                <div>
                   <img src={NigeriaLogo} style={{width:150, margin:16}} /> 
                </div>
                
                <div style={{display:'flex', textAlign:'center',flexDirection:'column', flex:1 }}>
                    
                    <span  style={{fontSize:27, fontWeight:500}}>Federal Republic Of Nigeria</span>
                    <span  style={{fontSize:26, fontWeight:400}}>Bank Verification Number Slip (BVN)</span>
                </div>
                <div>
                    <img src={Bvn} style={{width:150, margin:16}} />
                </div>
                
               </div>

               <div className='ninsearch'>
                    <div className='cdiv'>
                        <div><span>First Name</span> <span>{data?.FirstName}</span></div>
                        <div><span>Middle Name</span> <span>{data?.MiddleName}</span></div>
                        <div><span>Last Name</span> <span>{data.LastName}</span></div>
                        <div><span>Date of birth</span> <span>{data?.DateOfBirth}</span></div>
                        <div> <span>Marital Status</span> <span>{data?.maritalStatus}</span> </div>
                        <div> <span>Phone Number</span> <span>{data?.PhoneNumber1}</span> </div>
                        <div> <span>Gender</span> <span>{data?.Gender}</span> </div>
                        <div> <span>Enrollment Institution</span> <span>{data?.enrollmentBank}</span> </div>
                        <div> <span>State of Origin</span> <span>{data?.stateOfOrigin}</span> </div>
                        <div> <span>State of Resident</span> <span>{data?.stateOfResidence}</span> </div>
                        <div> <span>Residential Address</span> <span>{data?.residentialAddress}</span> </div>
                        <div> <span></span> <span></span> </div>
                    </div>
                    <div className='cdiv'>
                        <p style={{height:300}}>
                        <img src={`data:image/jpeg;base64,${data?.ImageBase64}`} style={{height:150, margin:16}} />
                        <p >
                            <p><span style={{fontSize:35, fontWeight:600, fontFamily:'Inter'}}>{data?.BVN}</span></p>
                            <p><span style={{fontSize:25, fontWeight:600, fontFamily:'Inter'}}>BVN</span></p>
                            
                        </p>
                        </p>
                    <div> <span>Nin</span> <span>{data?.nin}</span> </div>
                    <div> <span>Enrollment Branch</span> <span>{data?.enrollmentBranch}</span> </div>
                    <div><span>Origin LGA</span> <span>{data?.lgaOfOrigin}</span></div>
                    <div><span>Residential LGA</span> <span>{data?.lgaOfResidence}</span></div>
                    </div>

               </div>
              
               
            </div>

<div>
<div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                <button className='greenbutton no-print'  style={{backgroundColor:'red'}}>Close</button>
                              </div>
</div>

    </div>
   </IdLayout>
  )
}

export default BvnSearchResult