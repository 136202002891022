import React, { useState } from 'react';

import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone, faMessage } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import IdHomeTop from '../components/hometop';

function About() {
    const [staySigned, setStaySigned] = useState(false)
    const navigate = useNavigate();
    const onLogin=()=>{
        navigate('/login');
    }
  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
        <IdHomeTop active="about" />
       <div style={{display:'flex'}}>
            <div style={{justifyContent:'right', flex:1, textAlign:'right'}}>
                <img src={HomeImg} style={{width:645, maxWidth:'90%'}} />
            </div>
            <div style={{ alignSelf:'center' , flex:1}}>
                
                <div style={{margin:'auto'}}>
                   <span className='superbig'>About Us</span>
                   <span className='superbig'>Customers Portal</span> 
                </div>
                   <p style={{fontSize:18, fontFamily:'Inter',  textAlign:'justify', marginTop:35, marginBottom:40, fontWeight:500}}>
                   IDVerify as an identity verification system is designed in-line with the specifics of National biometrics standard requirements, standardization of
                   data collection, transmission and storage processes to cover the basic functions of enrolment, verification and identification.
                    </p> 
                <p style={{fontSize:18, fontFamily:'Inter',  textAlign:'justify', fontWeight:500}}>
                Our system ensures consistent, good quality biometrics data and reliable interoperability across biometric capture devices, capture software
                and unique NIN service delivery. This will enable detection and elimination of duplicate identities and ensure  accurate authentication
                </p>
            </div>
            
       </div>  
       <div className='minifooter' style={{ height:70, backgroundColor:'#D9D9D93D'}}>
        <div style={{margin:'auto', fontSize:16, fontFamily:'Inter', fontWeight:'500'}}> 
        <span style={{marginLeft:10, marginRight:10}}>Copyright <FontAwesomeIcon icon={faCopyright} /> 2024 Idverify.com.ng</span> 
        <span style={{marginLeft:10, marginRight:10}}>Head office: Head Office: 27B, Royal Palm Drive, Osborne Foreshore II, Ikoyi lagos...</span>
        <span style={{marginLeft:10, marginRight:10}}><FontAwesomeIcon icon={faMessage} /> info@afritech.com.ng</span>
        <span style={{marginLeft:10, marginRight:10}}><a href='tel:01 2916946'><FontAwesomeIcon icon={faPhone} /> 01 2916946</a></span>
        </div>
  
       </div>
        </div>
      
    </div>
  )
}

export default About