import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck, faSquare,  faEye, faEyeSlash, } from '@fortawesome/free-regular-svg-icons';


import HomeImg from '../images/homebody.png';
import IdInput from '../components/idInput';
import { Link, useNavigate } from 'react-router-dom'
import IdHomeTop from '../components/hometop';
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';
import LoadingImg from '../images/loading.gif';

function ForgetPassword() {
    const navigate = useNavigate();
    const { profile , setProfile, setToken} = useContext(ShareContext);
    const [isSuccess, setIsSuccess] = useState(false)
    

   
    const [email, setEmail]=useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    /* const onLogin=()=>{
        navigate('/dashboard');
    } */
    const onForget=async(e)=>{
      e.preventDefault(); 
        setLoading(true);
        
        try {
          var v={username:email, password:''};
          const resp = await fetch(`${Constants.rooturl}/customer/resetrequest`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              setIsSuccess(true);
             
          }else {   
            setErr('Possibly the email is not on record.. Correct it and try again');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }


  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
          <IdHomeTop active="forget" />
       <div style={{display:'flex'}}>
            <div style={{width:500, maxWidth:'80%', margin:'auto'}}>
               {!isSuccess && <form onSubmit={onForget}>
                    <div className='idtab'>
                    
                    <div  className='tabitem tabactive'><div><span>Forget Password</span> </div> </div>
                        </div>
                    <IdInput label="Email" value={email} onChange={(e)=>setEmail(e.target.value)}  required />
                        <p>Provide your registered email on our record. You will receive email to help you do password reset.</p>
                  

                    
                    {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='greenbutton' style={{width:'100%'}} type="submit">Forget Password</button>
                        </div>         
                        
                              </div>} 
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                    <div><span>I did not forget my password, <Link to={'/login'}> Login</Link></span></div>
                </form>} 

                {isSuccess && <div style={{textAlign:'center'}}>
                    <h1>Reset Successful</h1>
                    <h3>We have sent you email with reset password details</h3>
                    <div style={{marginTop:16}}>
                    <Link to={'/'}> Home</Link> | <Link to={'/login'}> Login</Link>
                    </div>
                    </div>}
                
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default ForgetPassword