import React, { useState } from 'react';

import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone, faMessage, faSearch, faHome } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import IdHomeTop from '../components/hometop';

function Contact() {
    const [staySigned, setStaySigned] = useState(false)
    const navigate = useNavigate();
    const onLogin=()=>{
        navigate('/login');
    }
  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
        <IdHomeTop active="contact" />
       <div style={{display:'flex'}}>
            <div style={{ alignSelf:'center' , flex:1}}>
                
                <div style={{margin:'auto'}}>
                   <span className='superbig'>Contact Us</span>
                   
                </div>
                   <p style={{fontSize:18, fontFamily:'Inter',  textAlign:'justify', marginTop:35, marginBottom:40, fontWeight:500}}>We are willing to engage you</p> 
                <button className='greenbutton'>Learn More</button>
            </div>
            <div style={{justifyContent:'right', flex:1, textAlign:'right'}}>
            <div className='singlebox'>
                    <FontAwesomeIcon icon={faHome} />
                    <p className='head'>Head Office</p>
                    <p>27B, Royal Palm Drive,  Osborne Foreshore II,Ikoyi, Lagos.</p>
                    <p><FontAwesomeIcon icon={faMessage} /> info@afritech.com.ng</p>
                    <p><FontAwesomeIcon icon={faPhone} /> <a href="tel:012916946">01 2916946</a></p>
                    </div>
                    <div className='singlebox'>
                    <FontAwesomeIcon icon={faHome} />
                    <p className='head'>Minna Office</p>
                    <p>C5/C6, Bahago Plaza,Tunga, Minna.</p>
                   
                    </div>     
                    <div className='singlebox'>
                    <FontAwesomeIcon icon={faHome} />
                    <p className='head'>Bida Office</p>
                    <p>Opposite Aliyu Memorial Stadium,Mokwa Road, Bida.</p>
                    <p><FontAwesomeIcon icon={faPhone} /> <a href="tel:+2349070073333">234 907 007 3333</a> </p>
                    </div>      
            </div>
       </div>  
       <div className='minifooter' style={{ height:70, backgroundColor:'#D9D9D93D'}}>
        <div style={{margin:'auto', fontSize:16, fontFamily:'Inter', fontWeight:'500'}}> 
        <span style={{marginLeft:10, marginRight:10}}>Copyright <FontAwesomeIcon icon={faCopyright} /> 2024 Idverify.com.ng</span> 
        <span style={{marginLeft:10, marginRight:10}}>Head office: Head Office: 27B, Royal Palm Drive, Osborne Foreshore II, Ikoyi lagos...</span>
        <span style={{marginLeft:10, marginRight:10}}><FontAwesomeIcon icon={faMessage} /> info@afritech.com.ng</span>
        <span style={{marginLeft:10, marginRight:10}}><a href='tel:01 2916946'><FontAwesomeIcon icon={faPhone} /> 01 2916946</a></span>
        </div>
  
       </div>
        </div>
      
    </div>
  )
}

export default Contact