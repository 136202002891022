import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Bvn from  '../../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import * as Constants from '../../Constants';
import tinData from '../../data/finger.json';


function LicenseSearchResult() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
   // const {type} = useParams(); 
    
    //const data=bvnData.ResponseData;

  console.log(tinData);

    useEffect(() => { 
        
        Constants.seo({title:'Driver License Search',metaDescription:''}); 
    }, []);

    const onPrint=()=>{
        window.print();
    }

  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

            <div style={{display:'flex', flexDirection:'row', flex:1}}>
                <div>
                   <img src={NigeriaLogo} style={{width:150, margin:16}} /> 
                </div>
                
                <div style={{display:'flex', textAlign:'center',flexDirection:'column', flex:1 }}>
                    
                    <span  style={{fontSize:27, fontWeight:500}}>Federal Republic Of Nigeria</span>
                    <span  style={{fontSize:26, fontWeight:400}}>Bank Verification Number Slip (BVN)</span>
                </div>
                <div>
                    <img src={Bvn} style={{width:150, margin:16}} />
                </div>
                
               </div>

               <div className='ninsearch'>
                    <div className='cdiv'>
                       
                        <div> <span></span> <span></span> </div>
                    </div>
                    

               </div>
              
               
            </div>

<div>
<div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                <button className='greenbutton no-print'  style={{backgroundColor:'red'}}>Close</button>
                              </div>
</div>

    </div>
   </IdLayout>
  )
}

export default LicenseSearchResult