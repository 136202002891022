import React, { useState } from 'react';

import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone, faMessage, faSearch, faTools, faCloud } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import IdHomeTop from '../components/hometop';

function HowItWork() {
    const [staySigned, setStaySigned] = useState(false)
    const navigate = useNavigate();
    const onLogin=()=>{
        navigate('/login');
    }
  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
        <IdHomeTop active="howitwork" />
       <div style={{display:'flex'}}>
            <div style={{ alignSelf:'center' , flex:1}}>
                
                <div style={{margin:'auto'}}>
                   <span className='superbig'>How It Works</span>
                   <span className='superbig'>Customers Portal</span> 
                </div>
                   <p style={{fontSize:18, fontFamily:'Inter',  textAlign:'justify', marginTop:35, marginBottom:40, fontWeight:500}}>IdVerify verification is the safest and fastest way to know the identity of the individual
                   trying to carry out business with entity</p> 
                <button className='greenbutton'>Learn More</button>
            </div>
            <div style={{justifyContent:'right', flex:1, textAlign:'right'}}>
                <div>
                    <div className='singlebox'>
                    <FontAwesomeIcon icon={faSearch} />
                    <p className='head'>NIN Verification</p>
                    <p>NIN Verification will validate the data of the individual and confirm the  NIN and Virtual NIN</p>
                    </div>
                    <div className='singlebox'>
                    <FontAwesomeIcon icon={faTools} />
                    <p>NIN RETRIEVAL</p>
                    <p>NIN Retrieval will reveal NIN of the individual with status</p>
                    </div>
                    <div className='singlebox'>
                    <FontAwesomeIcon icon={faCloud} />
                    <p>KYC</p>
                    <p>KYC enables the organization/bodies  to obtain information about the individual</p>
                    </div>
                </div>
            </div>
       </div>  
       <div>
        <div className='singlebox'>
            <p className='head'>What is Virtual NIN</p>
            <p>NIN tokenization helps to protect an individual’s data privacy via the use of an encrypted, coded representation (“disguised”) version of the NIN rather than actual NIN itself in day-to-day transactions.</p>
            <p className='head'>How It Works via USSD</p>
            <p><ul>
                <li>To generate a Virtual NIN via USSD, dial *346*3*Your NIN*115038#</li>
                <li>An SMS message will be sent back to you containing the Virtual NIN generated for you.</li>
                </ul></p>
             <p className='head'>How It Works on the MWS Mobile App</p>   
             <p>
                <ul>
                    <li>Launch the MWS Mobile ID app installed on your device (Android or iOS). Make sure you have the current version of the app installed or updated on your mobile device.</li>
                    <li>Enter your PIN on the lock screen to continue.</li>
                    <li>Select the “GET VIRTUAL NIN” button on the “Home” screen.</li>
                    <li>Read through the “Enhanced Data Privacy” text.</li>
                    <li>Then click on the button with the “+” sign on the bottom right corner of the screen to start the process of generating a Virtual NIN for the verifying Enterprise you are dealing with.</li>
                    <li>Tap on any of the available options to either scan the Enterprise’s QR code or type in the Enterprise’s ID(115038) or Search the name ( AFRITECH MULTICONCEPTS LIMITED ).</li>
                    <li>A Virtual NIN is generated for you to use specifically with that verifying Enterprise alone.</li>
                    <li>Present the Virtual NIN to the Enterprise for verification.</li>
                </ul>
             </p>
        </div>
       </div>
       <div className='minifooter' style={{ height:70, backgroundColor:'#D9D9D93D'}}>
        <div style={{margin:'auto', fontSize:16, fontFamily:'Inter', fontWeight:'500'}}> 
        <span style={{marginLeft:10, marginRight:10}}>Copyright <FontAwesomeIcon icon={faCopyright} /> 2024 Idverify.com.ng</span> 
        <span style={{marginLeft:10, marginRight:10}}>Head office: Head Office: 27B, Royal Palm Drive, Osborne Foreshore II, Ikoyi lagos...</span>
        <span style={{marginLeft:10, marginRight:10}}><FontAwesomeIcon icon={faMessage} /> info@afritech.com.ng</span>
        <span style={{marginLeft:10, marginRight:10}}><a href='tel:01 2916946'><FontAwesomeIcon icon={faPhone} /> 01 2916946</a></span>
        </div>
  
       </div>
        </div>
      
    </div>
  )
}

export default HowItWork