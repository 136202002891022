import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Nimc from  '../../images/nimc.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';
import * as Constants from '../../Constants';
import IdLayout from '../../components/_layout';
import sData from "../../data/vnin.json";


function VNinSearchResult() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [view, setView] = useState('front');
   // const {type} = useParams(); 
    
    const [data, setData]= useState({});
    console.log('sData ********');
    console.log(sData);
    console.log('raw Data**********');
    console.log(raw);

    useEffect(() => { 
        Constants.seo({title:'NIN Search',metaDescription:''}); 
       // setData(raw);
       setData(raw);
    }, []);

    const onPrint=()=>{
        window.print();
    }

    const onSwitchView=()=>{
        setView(view=='front'?'back':'front');
    }
  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
        <div>
            <button type='button' className='greenbutton no-print' onClick={onSwitchView}>{view=='front'?'Switch To Back to print':'Switch to front to print'}</button>
        </div>
         {view=='front' && <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

               <div style={{display:'flex', flexDirection:'row', flex:1}}>
                <div>
                   <img src={NigeriaLogo} style={{width:150, margin:16}} /> 
                </div>
                
                <div style={{display:'flex', textAlign:'center',flexDirection:'column', flex:1 }}>
                  
                    <span  style={{fontSize:27, fontWeight:500}}>Federal Republic Of Nigeria</span>
                    <span  style={{fontSize:20, fontWeight:400}}>Virtual National Identification Number Slip (VNIN)</span>
                </div>
                <div>
                    <img src={Nimc} style={{width:150, margin:16}} />
                </div>
                
               </div>

               <div className='ninsearch' >
               <img src={`data:image/jpeg;base64,${data?.photograph}`} style={{height:300, margin:16}} />
                <table className='resultTable'>
                <tr><td>Virtual NIN :</td> <td>{data?.vNIN}</td></tr>
                <tr><td>Surname :</td> <td>{data?.surname}</td></tr>
                <tr><td>First name :</td> <td>{data?.firstName}</td></tr>
                <tr><td>Middle name :</td> <td>{data?.middleName}</td></tr>
                <tr><td>Phone Number :</td> <td>{data?.trustedNumber}</td></tr>
                <tr><td>Date of Birth :</td> <td>{data?.dateOfBirth}</td></tr>
                <tr><td>Gender :</td> <td>{data?.gender}</td></tr>
                <tr><td>Date verified :</td> <td>{data?.ts}</td></tr>
              </table>
              
               
               </div>
               <div>
                
               </div>
            
               
               
            </div>}   
            {view!='front' && <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

<div style={{display:'flex', flexDirection:'column', flex:1, maxWidth:'80%', marginRight:'auto', marginLeft:'auto', padding:16}}>

 

 <div style={{textAlign:'right'}}>
     <img src={Nimc} style={{width:150, margin:16}} />
 </div>
 <div style={{textAlign:'center'}}>
    <span>Verified</span>
    <p>This is a property of National Identity Management Commission (NIMC), Nigeria.
If found, please return to the nearest NIMC's office or
contact +234 815 769 1214, +234 815 769 1071</p>
 </div>
 <div>
    <ol>
        <li>This NIN slip remains the property of the Federal Republic of Nigeria, and MUST be surrendered on demand;</li>
        <li>This NIN slip does not imply nor confer citizenship of the Federal Republic of Nigeria on the individual the document is issued to;</li>
        <li>This NIN slip is valid for the lifetime of the holder and DOES NOT expire</li>
    </ol>
 </div>
</div>


<div>
 
</div>



</div>}      

<div>
<div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                <button className='greenbutton no-print'  style={{backgroundColor:'red'}}>Close</button>
                              </div>
</div>

    </div>
   </IdLayout>
  )
}

export default VNinSearchResult