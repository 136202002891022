import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Nimc from  '../../images/nimc.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';
import * as Constants from '../../Constants';
import IdLayout from '../../components/_layout';
import sData from "../../data/vnin.json";

function NinSearchResult() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
   // const {type} = useParams(); 
    
   const data=raw?.data.data;
   /*  const [data, setData]= useState({}); */

    const demodata=[
        {id:'H6Y0NYFH0000373', surname:'ABIODUN', firstname:'HARIKE', middlename:'TITILAYO', gender:'FEMALE', address:'10B Samfield Avenue', lga:'Lekki Phase 1', state:'Lagos.', nin:'56678904321', dateissue:'09/09/2023', imgpath:''},
        {id:'H6Y0NYFH0000378', surname:'', firstname:'', middlename:'', gender:'', address:'', lga:'', state:'', nin:'', dateissue:'', imgpath:''},

    ]

    useEffect(() => { 
        Constants.seo({title:'NIN Search',metaDescription:''}); 
     
    }, []);

    const onPrint=()=>{
        window.print();
    }

  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

               <div style={{display:'flex', flexDirection:'row', flex:1}}>
                <div>
                   <img src={NigeriaLogo} style={{width:150, margin:16}} /> 
                </div>
                
                <div style={{display:'flex', textAlign:'center',flexDirection:'column', flex:1 }}>
                    <span style={{fontSize:35, fontWeight:600, fontFamily:'Inter'}}>National Identity Management System</span>
                    <span  style={{fontSize:27, fontWeight:500}}>Federal Republic Of Nigeria</span>
                    <span  style={{fontSize:26, fontWeight:400}}>National Identification Number Slip (NINS)</span>
                </div>
                <div>
                    <img src={Nimc} style={{width:150, margin:16}} />
                </div>
                
               </div>

               <div className='ninsearch' >
                    <div className='cdiv'>
                        <div><span>Traking ID:</span> <span>{data.trackingId}</span></div>
                        <div><span>NIN:</span> <span>{data.nin}</span></div>
                        <div><span>Country of Birth:</span> <span>{data.birthcountry}</span></div>
                        <div><span>Date of Birth:</span> <span>{data.birthdate}</span></div>
                        <div><span>Residential:</span> <span>{data.residence_AdressLine1}, {data.residence_Town} {data.residence_lga} {data.residence_state} </span></div>
                        <div><span>State of Origin:</span><span>self_origin_state</span></div>
                    </div>
                    <div  className='cdiv'>
                    <div><span>Surname:</span> <span>{data.surname}</span></div>
                    <div><span>First Name:</span> <span>{data.firstname}</span></div>
                    <div><span>Middle Name:</span> <span>{data.middlename}</span></div>
                    <div><span>Gender:</span> <span>{data.gender}</span></div>
                    <div><span>Phone Number:</span> <span>{data.telephoneno}</span></div>
                    </div>
                    <div  className='cdivi'>
                        
                        <div style={{display:'flex', flexDirection:'column'}}>
                        <img  src={`data:image/jpeg;base64,${data?.photo}`}style={{height:250}} />
                        <img  src={`data:image/jpeg;base64,${data?.signature}`}style={{height:50, margin:16}} />
                        </div>
                    </div>
               </div>
<div>
   <span style={{fontWeight:700, margin:8}}>Note:</span> <span> This transaction slip does not confer the right to the General Multipurpose card (For enquiry please contact)</span>
</div>
             {/*   <div className='ninsearch_last'>
                    <div>
                    <FontAwesomeIcon icon={faCloud} /> 
                    <span>belpdeck@nimc.gov.ng</span>
                    </div>
                    <div>
                        <span>www.nimc.gov.ng</span>
                    </div>
                    <div>
                    <FontAwesomeIcon icon={faPhone} /> 
                    <span>09087653412, 08123457689</span>
                    </div>

                    <div>
                    <FontAwesomeIcon icon={faCalendar} /> 
                    <span>National Identity Management Commision</span>
                    <span>11, Salako Crecent Off Ajayi Road, Abuja, Nigeria.</span>
                    </div>

               </div> */}
               
            </div>

<div>
<div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                <button className='greenbutton no-print'  style={{backgroundColor:'red'}}>Close</button>
                              </div>
</div>

    </div>
   </IdLayout>
  )
}

export default NinSearchResult