import React, { useState , useEffect, useContext} from 'react';



import Logo from '../images/logo.png';
import Naked from '../images/naked.png';
import NigeriaLogo from '../images/nigerialogo.png';
import Bvn from  '../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';

import IdLayout from '../components/_layout';

import tinData from '../data/finger.json';
import * as Constants from '../Constants';
import IdInput from '../components/idInput';


function CallPayment() {
    const { profile,raw,activeType, token } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [amount,setAmount] =useState(0);
    const [units,setUnits] =useState(0);
    const [plan, setPlan] =useState({id:1, name:'Standard', minUnit:1, costPerUnit:300});
    const [plans, setPlans] = useState([]);
    const [loading, setLoading]=useState(false);
    const [hasPlan, setHasPlan]=useState(false);

    const LoadPlans = () => {


      fetch(`${Constants.rooturl}/customer/plans`,{
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Authorization": token
          },
      })
      .then((resp) => resp.json())
      .then((data) => {
         setPlans(data);
         //get a singular plan
         var p = data.filter(function (item, index) { return item.id == profile.planId; });
        if(p.length>0){
          setHasPlan(true);
          setPlan(p[0])
        }
      }).catch((error) => {
          console.log(error)
          setLoading(false)
      })
    
    
      }
   // const {type} = useParams(); 
   const navigate = useNavigate();
    //const data=bvnData.ResponseData;


    useEffect(() => { 
       // Constants.seo('','');
       console.log(profile);
        Constants.seo({title:'IdVerify Payment',metaDescription:''}); 
        LoadPlans();
    }, []);

    const onPrint=()=>{
        window.print();
    }

    const onCancel=()=>{

    }

    const loadPlans=()=>{
      //load all plans

    }

  return (

   <IdLayout active={activeType}  profile={profile} >
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
      <div style={{display:'flex', flexDirection:'row'}}>
      <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>


         <p>  </p>
         <form className='form-inp' action='/paystack.html'>
         <IdInput label="Enter Number of units to buy:" type='number' min={plan.minUnit} value={units} onChange={(e)=>setUnits(e.target.value)}/>
<div className="success-box">
         <h2>Amount {Constants.formatCurrency(units*plan.costPerUnit) }</h2>
             <input type='hidden' name="name" value={profile?.firstName}  />
             <input type='hidden' name="email" value={profile?.email}   />
             <input type='hidden' name="phone" value={profile?.phone}   />
             <input type='hidden' name="returnurl" value={`${Constants.baseurl}/returnpayment/`}   />
             <input type='hidden' name="amount" value={units*plan.costPerUnit} />
             <input type='hidden' name="docref" value={`${profile?.id}`} />
             <input type='hidden' name="planid" value={plan.id} />
             <input type='hidden' name="userid" value={`${profile?.id}`} />
             <input type='hidden' name="units" value={`${units}`} />
           <button className='greenbutton'  type='submit'>Pay Now</button>  <button className='red' onClick={onCancel} type='button'>Cancel</button> 
       </div>     
         </form>  
    

    <div className='ninsearch'>
         <div className='cdiv'>
            
             <div> <span></span> <span></span> </div>
         </div>
         

    </div>
   
    
      </div>
      <div style={{flex:1, marginLeft:20, padding: 10}}>
      <p style={{color:'red'}}>Note before payment</p>
      <h3>One (1) Verification unit costs {plan.costPerUnit} NGN</h3>
     Current Plan: {plan.name}. <Link>Change Plan</Link>
      <p>Ensure you complete the payment process for your wallet to be credited appropriately. Thank you</p>
      </div>
      </div>
        

<div>

</div>

    </div>
   </IdLayout>
  )
}

export default CallPayment