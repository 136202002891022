import React, { useState , useEffect, useContext} from 'react';



import Logo from '../images/logo.png';
import Naked from '../images/naked.png';
import NigeriaLogo from '../images/nigerialogo.png';
import Bvn from  '../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';

import IdLayout from '../components/_layout';

import tinData from '../data/finger.json';
import * as Constants from '../Constants';
import IdInput from '../components/idInput';
import LoadingImg from '../images/loading.gif';


function ReturnPayment() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const {refid} = useParams();
    const [loading, setLoading]=useState(false);
    const [sucess, setSuccess] =useState(false);
    const [resdata, setResdata]= useState(null);
    const [isRun, setIsRun]= useState(false);


   // const {type} = useParams(); 
   const navigate = useNavigate();
    //const data=bvnData.ResponseData;

  console.log(tinData);

    useEffect(() => { 
       // Constants.seo('','');
        Constants.seo({title:'Payment',metaDescription:''}); 
        if(refid!=undefined){
            //verifiy payment 
            onVerifyPayment();

        }else{
            //return to dashbord
        }
    }, []);

    const onVerifyPayment= async()=>{ //doctype=upline always this one
        //for account registration DocumentView
        if(isRun){
          return;
        }
        setIsRun(true);
        const url=`${Constants.rooturl}/customer/payment/verify/${refid}`
    
        console.log(`the calling url ${url}`);
        try {
            setLoading(true);
            const resp = await fetch(url, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
              },
            });
            
            const data = await resp.json();
            setLoading(false);
           // onLoad();
           console.log(data);
            if(data.code==0 ){
              // navigate(`/AffidavitDocuments/${data.docId}/edit/${data.uplineId}`)
              setResdata(data);
             
            }else{
               // setAlert({open:true, title:'Payment Status', subtitle:data.status, code:data.code, data, onOk})
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            setResdata({code:-12, status:error});
            
          }
    }

    const onPrint=()=>{
        window.print();
    }

    const onCancel=()=>{

    }

  return (

   <IdLayout active={activeType} profile={profile} >
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>
             {resdata!=null && <div className='ninsearch'>
                   {resdata?.code==0 && <div className='cdiv' style={{textAlign:'center'}}>   
                        <h2>Payment Successful</h2>
                        <h3>{Constants.formatCurrency(resdata.payres.data.amount/100)}</h3>
                        <p>{resdata.payres.data.reference}</p>
                    </div>} 
                    {resdata?.code!=0 && <div className='cdiv' style={{textAlign:'center'}}>   
                        <h2 style={{color:'red'}}>Payment failed</h2>
                        <h3>Possible duplicate reference or invalid reference</h3>
                    </div>} 
               </div> }  

               {resdata==null && <div className='ninsearch'>
                <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                <p>Loading</p>
                </div>} 
            </div>
    </div>
   </IdLayout>
  )
}

export default ReturnPayment